import Vue from 'vue';
import { Toast } from 'vant';
import axios from 'axios';
import qs from 'qs';
import router from '../router';

Vue.use(Toast);

axios.defaults.headers['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.common['platformId'] =
  sessionStorage.getItem('platformId');
axios.defaults.headers.common['token'] = sessionStorage.getItem('token');

const service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 30000,
});

service.interceptors.request.use(
  (config) => {
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (res) => {
    const { rtnCode, rtnMessage, success } = res.data;
    if (rtnCode == '-9999') {
      router.replace({
        path: '/login',
      });
    }
    if (success) {
      return res;
    } else {
      Toast(rtnMessage);
      // return Promise.reject(res.data);
      return res;
    }
  },
  (error) => {
    console.log(error);
    let { url } = error.config;
    console.log(url);
    if (
      ![
        '/tax/wechat/yunDa/userCommit',
        '/tax/wechat/yunDa/saveDriverColl',
      ].includes(url)
    ) {
      Toast('系统繁忙');
    } else {
      return error.response;
    }
  }
);

export default service;
