import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/pages/Login';
import Acquisition from '@/pages/Acquisition';
import Driver from '@/pages/Driver';
import DriverDetail from '@/pages/DriverDetail';
import Registration from '@/pages/Registration';

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const router = new Router({
  mode: 'history',
  routes: [
    // {
    //   path: '/',
    //   redirect: '/login',
    // },
    {
      path: '/acquisition',
      name: 'acquisition',
      component: Acquisition,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/driver',
      name: 'driver',
      component: Driver,
    },
    {
      path: '/driverDetail',
      name: 'driverDetail',
      component: DriverDetail,
    },
    {
      path: '/registration',
      name: 'registration',
      component: Registration,
    },
  ],
});

// router.beforeEach((to, from, next) => {
//   console.log(to, from);
//   next();
// });

export default router;
