<template>
  <div class="Driver">
    <div class="banner">
      <b>司机管理</b>
    </div>
    <div class="tabs">
      <van-tabs
        v-model="active"
        title-active-color="#fff"
        title-inactive-color="#fff"
        @click="handleTabClick"
      >
        <van-tab name="1" title="待采集"></van-tab>
        <van-tab name="2" title="采集完成"></van-tab>
        <van-tab name="3">
          <template #title>
            <span class="title">用工方审核</span>
          </template>
        </van-tab>
        <van-tab name="4" title="驳回"></van-tab>
        <van-tab name="5" title="已完成"></van-tab>
      </van-tabs>
    </div>

    <form action="/">
      <van-search
        v-model="value"
        show-action
        placeholder="请输入司机姓名"
        @search="onSearch"
        @cancel="onCancel"
      />
    </form>

    <div v-if="!list.length && !loading && finished" class="empty">
      <van-empty description="暂无数据" />
    </div>
    <div v-else class="common-recommend-list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="item" v-for="item in list" :key="item.id">
          <!-- @click="toDetail(item)" -->
          <div class="name van-ellipsis">{{ item.taxCenterName }}</div>
          <div class="flex">
            <div>姓名：</div>
            <div class="date van-ellipsis">{{ item.name }}</div>
          </div>
          <div class="flex">
            <div>性别：</div>
            <div>{{ item.gender }}</div>
          </div>
          <div class="flex">
            <div>身份证号:</div>
            <div>{{ item.idNo }}</div>
          </div>
          <div class="actions flex">
            <van-button
              v-if="[1, 4].includes(item.status)"
              size="small"
              type="primary"
              @click.stop="toRegist(item)"
              >考试登记</van-button
            >
            <van-button
              size="small"
              type="primary"
              @click.stop="toDetail(item.id)"
              >详情</van-button
            >
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Cell, Empty, List, Search, Tab, Tabs, Toast } from 'vant';

Vue.use(Cell).use(Empty).use(List).use(Search).use(Tab).use(Tabs).use(Toast);

export default {
  name: 'Driver',
  components: {},
  data() {
    return {
      list: [],
      active: 0,
      pageNo: 1,
      loading: false,
      finished: false,
      total: 0,
      value: '',
    };
  },
  created() {},
  methods: {
    handleTabClick(name, title) {
      this.pageNo = 1;
      this.list = [];
      this.getTaskList();
    },
    onLoad() {
      this.getTaskList();
    },
    async getTaskList() {
      this.finished = false;
      this.loading = true;
      const { data } = await this.$axios.post('/tax/yunDa/queryCollByPage', {
        status: Number(this.active),
        name: this.value,
        page: this.pageNo,
        limit: 10,
      });
      if (data.success) {
        const { list, total, pages } = data.data;
        this.list = [...this.list, ...list];
        this.total = total;
        if (this.pageNo < pages) {
          this.pageNo++;
        } else {
          this.finished = true;
        }
      } else {
        this.finished = true;
      }
      this.loading = false;
    },
    onSearch() {
      this.pageNo = 1;
      this.list = [];
      this.getTaskList();
    },
    onCancel() {
      this.pageNo = 1;
      this.list = [];
      this.getTaskList();
    },
    toDetail(id) {
      this.$router.push({
        name: 'driverDetail',
        query: { id },
      });
    },
    toRegist({ id, idNo, name }) {
      this.$router.push({
        name: 'registration',
        query: { id, idNo, name },
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/vars';

.Driver {
  padding-bottom: 0.5rem;

  .banner {
    height: 1.6rem;
    background-image: linear-gradient(45deg, $orange 2%, #fe6905 90%);
    text-align: center;

    b {
      display: inline-block;
      margin-top: 0.5rem;
      color: #fff;
      font-size: 0.22rem;
      vertical-align: top;
      letter-spacing: 0.06rem;
    }
  }

  .tabs {
    margin-top: -0.44rem;

    .van-tabs__nav {
      background: rgba(#fff, 0.3);
    }

    .van-tabs__line {
      height: 0.04rem;
      background: #fff;
    }

    .van-tabs--line .van-tabs__wrap {
      height: 0.44rem;
    }

    .title {
      display: block;
      width: 5em;
    }
  }

  .common-recommend-list {
    padding: 0 0.12rem;

    .item {
      position: relative;
      margin: 0.16rem 0;
      padding: 0.16rem;
      background: #fff;
      box-shadow: 0 0.04rem 0.2rem 0 rgba(#1d2538, 0.08);
      border-radius: 0.1rem;
      box-sizing: border-box;

      .name {
        line-height: 0.24rem;
        font-size: 0.17rem;
        font-weight: 500;
      }

      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.06rem;
      }

      .actions {
        .van-button {
          width: 1.4rem;
          line-height: 0.32rem;
          background: $orange;
          border-color: $orange;
          border-radius: 0.04rem;
          color: #fff;
          font-size: 0.14rem;

          & + .van-button {
            background: #fff;
            color: $orange;
          }
        }
      }
    }
  }
}
</style>
