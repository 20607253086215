var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"label":"资格证主页","rules":[
        {
          validator: _vm.validator,
          message: '请上传',
        },
      ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-uploader',{ref:"certFront",attrs:{"after-read":(file) => _vm.afterRead(file, 'certFront'),"max-count":1,"accept":"image/*"},on:{"delete":(file, current) => _vm.handleDelete(file, current, 'certFront')},model:{value:(_vm.form.certFront),callback:function ($$v) {_vm.$set(_vm.form, "certFront", $$v)},expression:"form.certFront"}})]},proxy:true}])}),_c('van-field',{attrs:{"label":"资格证副页"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-uploader',{ref:"certRec",attrs:{"after-read":(file) => _vm.afterRead(file, 'certRec'),"max-count":1,"accept":"image/*"},on:{"delete":(file, current) => _vm.handleDelete(file, current, 'certRec')},model:{value:(_vm.form.certRec),callback:function ($$v) {_vm.$set(_vm.form, "certRec", $$v)},expression:"form.certRec"}})]},proxy:true}])}),_c('van-field',{attrs:{"label":"资格证类别","placeholder":"请输入","rules":[{ required: true, message: '' }],"input-align":"right","readonly":""},model:{value:(_vm.form.certType),callback:function ($$v) {_vm.$set(_vm.form, "certType", $$v)},expression:"form.certType"}}),_c('van-field',{attrs:{"label":"初次领证日期","placeholder":"请选择","readonly":"","right-icon":"arrow","rules":[{ validator: _vm.validatorText, message: '请选择' }]},on:{"click":function($event){_vm.show = true;
        _vm.currentDate = new Date(_vm.form.certInit);
        _vm.current = 'certInit';}},model:{value:(_vm.form.certInit),callback:function ($$v) {_vm.$set(_vm.form, "certInit", $$v)},expression:"form.certInit"}}),_c('van-field',{attrs:{"label":"有效期起","placeholder":"请选择","readonly":"","right-icon":"arrow","rules":[{ validator: _vm.validatorText, message: '请选择' }]},on:{"click":function($event){_vm.show = true;
        _vm.currentDate = new Date(_vm.form.certStart);
        _vm.current = 'certStart';}},model:{value:(_vm.form.certStart),callback:function ($$v) {_vm.$set(_vm.form, "certStart", $$v)},expression:"form.certStart"}}),_c('van-field',{attrs:{"label":"有效期止","placeholder":"请选择","readonly":"","right-icon":"arrow","rules":[{ validator: _vm.validatorText, message: '请选择' }]},on:{"click":function($event){_vm.show = true;
        _vm.currentDate = new Date(_vm.form.certEnd);
        _vm.current = 'certEnd';}},model:{value:(_vm.form.certEnd),callback:function ($$v) {_vm.$set(_vm.form, "certEnd", $$v)},expression:"form.certEnd"}}),_c('div',{staticClass:"actions"},[_c('button',{staticClass:"btn btn-plain",on:{"click":_vm.handleBackward}},[_vm._v("上一步")]),_c('button',{staticClass:"btn",attrs:{"disabled":_vm.isSubmiting,"native-type":"submit"}},[_vm._v(" 下一步 ")])])],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('van-datetime-picker',{attrs:{"cancel-button-text":"清除","type":"date","title":"选择年月日","min-date":_vm.minDate,"max-date":_vm.maxDate},on:{"confirm":_vm.onConfirm,"cancel":_vm.onCancel},model:{value:(_vm.currentDate),callback:function ($$v) {_vm.currentDate=$$v},expression:"currentDate"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }