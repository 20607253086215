<template>
  <div class="Acquisition">
    <van-steps :active="active" active-color="#ff6d41">
      <van-step>身份证</van-step>
      <van-step>驾驶证</van-step>
      <van-step>资格证</van-step>
      <van-step>体检单</van-step>
      <van-step>完成</van-step>
    </van-steps>

    <Identity
      v-if="active == 0"
      :id="taxId"
      :yunDaUid="yunDaUid"
      :yunDaComId="yunDaComId"
      :drvrPhn="drvrPhn"
      @changeCurrent="changeCurrent"
    />
    <Driver
      v-if="active == 1"
      :id="taxId"
      :yunDaUid="yunDaUid"
      :yunDaComId="yunDaComId"
      @changeCurrent="changeCurrent"
    />
    <Qualification
      v-if="active == 2"
      :id="taxId"
      :yunDaUid="yunDaUid"
      :yunDaComId="yunDaComId"
      @changeCurrent="changeCurrent"
    />
    <Medical
      v-if="active == 3"
      :id="taxId"
      :yunDaUid="yunDaUid"
      :yunDaComId="yunDaComId"
      @changeCurrent="changeCurrent"
    />
    <Finish
      v-if="active == 4"
      :id="taxId"
      :yunDaUid="yunDaUid"
      :yunDaComId="yunDaComId"
      @changeCurrent="changeCurrent"
    />

    <van-popup v-model="show" position="bottom" :close-on-click-overlay="false">
      <div class="notification">
        <p>
          <b
            >温馨提示，您已进入灵活用工平台“{{
              taxCenterName
            }}”代驾身份认证流程：</b
          >
        </p>
        <p>
          本页面由“{{
            taxCenterName
          }}”提供服务,请仔细阅读相关要求、须知等内容。为保障您的权益，您在该页面的操作和信息将被安全记录并加密存储。
        </p>
        <button class="btn" @click="show = false">我知道了</button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import { Popup, Step, Steps } from 'vant';
import axios from '@/utils/request.js';
import Identity from '@/components/Identity.vue';
import Driver from '@/components/Driver.vue';
import Qualification from '@/components/Qualification.vue';
import Medical from '../components/Medical.vue';
import Finish from '../components/Finish.vue';

Vue.use(Popup).use(Step).use(Steps);

export default {
  name: 'Acquisition',
  components: {
    Identity,
    Driver,
    Qualification,
    Medical,
    Finish,
  },
  data() {
    return {
      active: 0,
      taxId: null,
      taxCenterName: '',
      yunDaUid: null,
      yunDaComId: null,
      drvrPhn: null,
      show: true,
    };
  },
  created() {
    this.getPlatformByUrl();

    const { drvrId, comId, drvrPhn } = this.$route.query;
    this.yunDaUid = drvrId;
    this.yunDaComId = comId;
    this.drvrPhn = drvrPhn;
  },
  methods: {
    async getPlatformByUrl() {
      const { data } = await this.$axios.post(
        '/system/platformController/getPlatformByUrl',
        {
          pcUrl: window.location.origin.includes('localhost')
            ? 'www.aaa.com'
            : window.location.origin,
        }
      );
      if (data.success) {
        let { taxId, id, platformName, taxCenterName } = data.data;
        this.taxId = taxId || 66;
        this.taxCenterName = taxCenterName;
        axios.defaults.headers.common['platformId'] = id;
        sessionStorage.setItem('platformId', id);
        document.title = platformName;

        this.getDetail();
      }
    },
    async getDetail() {
      const { data } = await this.$axios.post('/tax/wechat/yunDa/queryDriver', {
        yunDaUid: this.yunDaUid,
        yunDaComId: this.yunDaComId,
        taxId: this.taxId,
      });
      if (data.success) {
        if (data.data.status != 1) {
          this.form = data.data;
          this.active = 4;
        }
      }
    },
    changeCurrent(v) {
      this.active = v;
      document.querySelector('html').scrollTop = 0;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/vars';

.Acquisition {
  min-height: 100vh;
  background: #fff;
  padding: 0 0.16rem 0.32rem;

  .van-cell {
    padding-right: 0;
    padding-left: 0;
  }

  .send {
    height: 0.24rem;
    border-color: $orange;
    color: $orange;
    font-size: 0.14rem;
    vertical-align: top;
  }

  .count-down {
    height: 0.24rem;
    line-height: 0.24rem;
  }

  .van-radio {
    margin: 0.06rem 0.12rem;
    margin-left: 0;
  }

  .item-image {
    position: relative;
    z-index: 10;
    width: 0.8rem;
    height: 0.8rem;

    .van-uploader {
      z-index: -10;
      pointer-events: none;
    }

    .van-uploader__preview {
      display: none;
    }

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 0.8rem;
      height: 0.8rem;
      background-color: #f7f8fa;

      img {
        width: 100%;
        height: 100%;
      }

      .van-icon {
        color: #dcdee0;
        font-size: 0.24rem;
      }

      p {
        word-break: break-all;
      }
    }
  }

  // .field-image-group {
  //   position: relative;
  //   z-index: 10;
  //   width: 100%;

  //   .van-uploader {
  //     z-index: -10;
  //     pointer-events: none;
  //   }

  //   .van-uploader__preview {
  //     display: none;
  //   }
  // }

  .field-video {
    // .van-uploader {
    //   z-index: -10;
    //   pointer-events: none;
    // }

    .van-uploader__preview {
      display: none;
    }

    .van-field__control--custom {
      display: block;
    }

    & + p {
      margin-left: 0.12rem;
      line-height: 0.2rem;
      word-break: break-all;
    }
  }

  .btn {
    display: block;
    width: 100%;
    height: 0.44rem;
    background-color: $orange;
    margin: 0.32rem auto 0;
    padding: 0;
    border: 0.01rem solid $orange;
    border-radius: 0.22rem;
    color: #fff;
    font-size: 0.17rem;
    box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);

    &:disabled {
      background-color: $gray !important;
      border-color: $gray;
    }

    &.btn-plain {
      background: #fff;
      color: $orange;
    }
  }

  .actions {
    display: flex;
    .btn {
      width: 48%;
    }
  }

  .notification {
    padding: 0.16rem 0.16rem 0.32rem;

    p {
      b {
        display: block;
        margin-bottom: 0.06rem;
      }
    }
  }
}
</style>
