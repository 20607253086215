<template>
  <div>
    <van-form @submit="onSubmit">
      <van-field
        label="资格证主页"
        :rules="[
          {
            validator,
            message: '请上传',
          },
        ]"
      >
        <template #input>
          <van-uploader
            ref="certFront"
            :after-read="(file) => afterRead(file, 'certFront')"
            :max-count="1"
            @delete="
              (file, current) => handleDelete(file, current, 'certFront')
            "
            v-model="form.certFront"
            accept="image/*"
          >
          </van-uploader>
        </template>
      </van-field>

      <van-field label="资格证副页">
        <template #input>
          <van-uploader
            ref="certRec"
            :after-read="(file) => afterRead(file, 'certRec')"
            :max-count="1"
            @delete="(file, current) => handleDelete(file, current, 'certRec')"
            v-model="form.certRec"
            accept="image/*"
          >
          </van-uploader>
        </template>
      </van-field>

      <van-field
        v-model="form.certType"
        label="资格证类别"
        placeholder="请输入"
        :rules="[{ required: true, message: '' }]"
        input-align="right"
        readonly
      />

      <van-field
        v-model="form.certInit"
        label="初次领证日期"
        placeholder="请选择"
        readonly
        @click="
          show = true;
          currentDate = new Date(form.certInit);
          current = 'certInit';
        "
        right-icon="arrow"
        :rules="[{ validator: validatorText, message: '请选择' }]"
      />

      <van-field
        v-model="form.certStart"
        label="有效期起"
        placeholder="请选择"
        readonly
        @click="
          show = true;
          currentDate = new Date(form.certStart);
          current = 'certStart';
        "
        right-icon="arrow"
        :rules="[{ validator: validatorText, message: '请选择' }]"
      />

      <van-field
        v-model="form.certEnd"
        label="有效期止"
        placeholder="请选择"
        readonly
        @click="
          show = true;
          currentDate = new Date(form.certEnd);
          current = 'certEnd';
        "
        right-icon="arrow"
        :rules="[{ validator: validatorText, message: '请选择' }]"
      />

      <div class="actions">
        <button class="btn btn-plain" @click="handleBackward">上一步</button>
        <button :disabled="isSubmiting" class="btn" native-type="submit">
          下一步
        </button>
      </div>
    </van-form>

    <van-popup v-model="show" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        cancel-button-text="清除"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  DatetimePicker,
  Dialog,
  Field,
  Form,
  Popup,
  Toast,
  Uploader,
} from 'vant';
import OSS from 'ali-oss';
import Compressor from 'compressorjs';
import { getOssToken, getOssFile } from '@/utils/getOss';

Vue.use(DatetimePicker)
  .use(Dialog)
  .use(Field)
  .use(Form)
  .use(Popup)
  .use(Toast)
  .use(Uploader);

export default {
  name: 'Qualification',
  props: ['id', 'yunDaUid', 'yunDaComId'],
  data() {
    return {
      clientOSS: {},
      isSubmiting: false,
      uploading: false,
      form: {
        certFront: [],
        certRec: [],
        certType: '货运',
        certInit: '',
        certStart: '',
        certEnd: '',
        driverLicenseType: '',
        idNo: '',
      },
      certFront: [],
      certRec: [],
      show: false,
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2060, 11, 31),
      currentDate: new Date(),
      current: '',
      taxId: null,
    };
  },
  watch: {
    id: function (n, o) {
      this.taxId = n;
      this.getDetail();
    },
  },
  created() {
    this.getToken();

    this.randomString = this.getRandomString(10);
  },
  mounted() {
    this.taxId = this.id;
    this.taxId && this.getDetail();
  },
  methods: {
    validator(val) {
      // 校验函数返回 true 表示校验通过，false 表示不通过
      if (!val.length) {
        return ['C1', 'C2'].includes(this.form.driverLicenseType)
          ? true
          : false;
      }
    },
    validatorText(val) {
      // 校验函数返回 true 表示校验通过，false 表示不通过
      if (!val) {
        return ['C1', 'C2'].includes(this.form.driverLicenseType)
          ? true
          : false;
      }
    },
    async getDetail() {
      const { data } = await this.$axios.post('/tax/wechat/yunDa/queryDriver', {
        yunDaUid: this.yunDaUid,
        yunDaComId: this.yunDaComId,
        taxId: this.taxId,
      });
      if (data.success) {
        const {
          certFront,
          certRec,
          certInit,
          certStart,
          certEnd,
          driverLicenseType,
          idNo,
        } = data.data;
        this.form = {
          ...this.form,
          certInit,
          certStart,
          certEnd,
          driverLicenseType,
          idNo,
        };
        if (certFront) {
          const url = await getOssFile(certFront);
          this.form.certFront = [
            {
              url,
              isImage: true,
            },
          ];
          this.certFront = [certFront];
        }
        if (certRec) {
          const url = await getOssFile(certRec);
          this.form.certRec = [
            {
              url,
              isImage: true,
            },
          ];
          this.certRec = [certRec];
        }
      }
    },
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res);
        this.clientOSS = clientOSS;
      });
    },
    chooseFile(type) {
      this.$refs[type].chooseFile();
    },
    async afterRead(file, current) {
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `ling_gong/yunda/${date}/${t}-${this.randomString}-${current}${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        this[current].push(path);
      }
      this.uploading.clear();
    },
    handleCompressor(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          // quality: 0.3,
          maxWidth: 1000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    getRandomString(len) {
      len = len || 32;
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        maxPos = chars.length,
        pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    handleDelete(file, current, type) {
      this[type].splice(current, 1);
    },
    onConfirm(value) {
      this.form[this.current] = this.$dayjs(value).format('YYYY-MM-DD');
      this.current = '';
      this.show = false;
    },
    onCancel() {
      this.form[this.current] = '';
      this.current = '';
      this.show = false;
    },
    onSubmit(values) {
      console.log('form', this.form);
      this.handleSubmit();
    },
    async handleSubmit() {
      let postData = {
        ...this.form,
        taxId: this.taxId,
        yunDaUid: this.yunDaUid,
        yunDaComId: this.yunDaComId,
      };
      postData.certFront = postData.certFront.length ? this.certFront[0] : '';
      postData.certRec = postData.certRec.length ? this.certRec[0] : '';
      this.isSubmiting = true;
      const res = await this.$axios.post(
        '/tax/wechat/yunDa/saveDriverColl',
        postData
      );
      if (res.status != 200) {
        this.showError(`系统繁忙-${res.status}`);
        this.isSubmiting = false;
        return;
      } else {
        const { data } = res;
        if (data.rtnCode == 202) {
          this.showError(data.rtnMessage);
          this.isSubmiting = false;
          return;
        }
        if (data.success) {
          this.$emit('changeCurrent', 3);
        }
        this.isSubmiting = false;
      }
    },
    showError(msg) {
      Dialog.confirm({
        confirmButtonText: '重新提交',
        confirmButtonColor: '#ff6d41',
        cancelButtonText: '切换其他认证渠道',
        cancelButtonColor: '#969799',
        title: '提示',
        message: `提交失败: ${msg}，请稍后重试(若多次尝试仍失败，您可切换至其他认证渠道)`,
      })
        .then(() => {
          this.handleSubmit();
        })
        .catch(() => {
          window.location.href = 'thirdh5jump.driverapp.com/ylm/auth';
        });
    },
    handleBackward() {
      this.$emit('changeCurrent', 1);
    },
  },
};
</script>

<style lang="scss">
// @import '@/styles/vars';
</style>
