import request from '@/utils/request.js';

export const getOssToken = async () => {
  const { data } = await request({
    url: '/tool/oss/getOssToken',
    method: 'post',
  });
  if (data.success) {
    return data.data;
  }
};

export const getOssFile = async (url) => {
  const { data } = await request({
    url: '/tool/oss/getPrivateUrl',
    method: 'post',
    data: { url },
  });
  if (data.success) {
    return data.data;
  }
};
