<template>
  <div class="Login">
    <div class="form">
      <h1>登录</h1>

      <van-field
        v-model="form.accountName"
        label=""
        maxlength="18"
        placeholder="请输入帐号"
      />

      <van-field
        v-model="form.accountPwd"
        label=""
        maxlength="11"
        placeholder="请输入密码"
        type="password"
      />

      <van-button
        block
        class="submit"
        type="info"
        :disabled="disabled || submiting"
        @click="handleSubmit"
        >登录
      </van-button>

      <p>
        沪ICP备13007528号-2<br />
        沪公网安备 31011502014561号
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from '@/utils/request.js';
import { Button, Field, Toast } from 'vant';
Vue.use(Button).use(Field).use(Toast);

export default {
  name: 'Login',
  data() {
    return {
      form: {
        accountName: '',
        accountPwd: '',
      },
      submiting: false,
    };
  },
  computed: {
    disabled() {
      return !this.form.accountName || !this.form.accountPwd;
    },
  },
  created() {
    // const token = sessionStorage.getItem('token');
    // if (token) {
    //   this.$router.push('driver');
    // }

    this.getPlatformByUrl();
  },
  methods: {
    async getPlatformByUrl() {
      const { data } = await this.$axios.post(
        '/system/platformController/getPlatformByUrl',
        {
          pcUrl: window.location.origin.includes('localhost')
            ? 'www.aaa.com'
            : window.location.origin,
        }
      );
      if (data.success) {
        let { id, platformName } = data.data;
        axios.defaults.headers.common['platformId'] = id;
        sessionStorage.setItem('platformId', id);
        document.title = platformName;
      }
    },
    async handleSubmit() {
      this.submiting = true;
      const { data } = await this.$axios.post('/system/login', {
        ...this.form,
      });
      if (data.success) {
        const { token } = data.data;
        axios.defaults.headers.common['token'] = token;
        sessionStorage.setItem('token', token);
        this.$router.push('driver');
      }
      this.submiting = false;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/vars';

.Login {
  min-height: 100vh;
  background: $orange;

  .form {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2.63rem;
    background: #fff;
    padding: 0.28rem;
    border-radius: 0.06rem;
    transform: translateX(-50%) translateY(-50%);

    .van-cell {
      padding: 0.12rem 0;
      border-radius: 0.06rem;

      & + .van-cell {
        margin-top: 0.08rem;
      }

      &::after {
        content: none;
      }

      .van-field__control {
        font-size: 0.16rem;

        &::placeholder {
          color: #aaa;
        }
      }
    }

    .send {
      height: 0.32rem;
      border-color: $orange;
      color: $orange;
      font-size: 0.14rem;
    }

    .count-down {
      height: 0.32rem;
      line-height: 0.32rem;
    }

    .submit {
      width: 100%;
      height: 0.42rem;
      background: $orange;
      margin: 0.32rem 0 0;
      border: none;
      border-radius: 0.06rem;
      font-size: 0.18rem;
    }

    h1 {
      position: absolute;
      right: 0;
      top: -0.7rem;
      left: 0;
      color: #fff;
      font-size: 0.24rem;
      font-weight: normal;
      text-align: center;
    }

    p {
      position: absolute;
      right: 0;
      bottom: -0.5rem;
      left: 0;
      text-align: center;
      color: rgba(#fff, 0.6);
      font-size: 0.12rem;
      text-align: center;
    }
  }
}
</style>
