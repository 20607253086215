<template>
  <div class="Exam">
    <van-nav-bar
      fixed
      left-arrow
      title="考试信息登记"
      @click-left="$router.back()"
    />

    <van-form @submit="onSubmit">
      <van-field v-model="form.name" label="姓名" readonly />
      <van-field v-model="form.idNo" label="身份证号" readonly />

      <van-field
        label="考试登记表"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <template #input>
          <van-uploader
            ref="examTable"
            :after-read="(file) => afterRead(file, 'examTable')"
            :max-count="3"
            @delete="handleDelete"
            v-model="form.examTable"
            accept="image/*"
          >
          </van-uploader>
        </template>
      </van-field>

      <van-field
        v-model="form.examTime"
        label="考试日期"
        placeholder="请输入"
        readonly
        @click="show = true"
        right-icon="arrow"
        :rules="[{ required: true, message: '' }]"
      />

      <van-field label="倒库视频" class="field-video">
        <template #input>
          <van-uploader
            ref="revPark"
            :after-read="(file) => afterReadVideo(file, 'revPark')"
            :max-count="1"
            :max-size="300 * 1024 * 1024"
            @oversize="onOversize"
            accept="video/*"
            v-model="form.revPark"
          >
            <div class="inner">
              <van-button plain icon="plus" color="#ff6d41" size="small" />
              <p v-if="revPark.length">{{ revPark[0] }}</p>
            </div>
          </van-uploader>
        </template>
      </van-field>

      <van-field label="三检视频" class="field-video">
        <template #input>
          <van-uploader
            ref="threeInsp"
            :after-read="(file) => afterReadVideo(file, 'threeInsp')"
            :max-count="1"
            :max-size="300 * 1024 * 1024"
            @oversize="onOversize"
            accept="video/*"
            v-model="form.threeInsp"
          >
            <div class="inner">
              <van-button plain icon="plus" color="#ff6d41" size="small" />
              <p v-if="threeInsp.length">{{ threeInsp[0] }}</p>
            </div>
          </van-uploader>
        </template>
      </van-field>

      <button :disabled="isSubmiting" class="btn" native-type="submit">
        提交
      </button>

      <van-popup v-model="show" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onConfirm"
          @cancel="show = false"
        />
      </van-popup>
    </van-form>
  </div>
</template>

<script>
import Vue from 'vue';
import { Divider, Field, Form, NavBar, Toast, Uploader } from 'vant';
import OSS from 'ali-oss';
import Compressor from 'compressorjs';
import { getOssToken, getOssFile } from '@/utils/getOss';

Vue.use(Divider).use(Field).use(Form).use(NavBar).use(Toast).use(Uploader);

export default {
  name: 'Registration',
  data() {
    return {
      clientOSS: {},
      isSubmiting: false,
      uploading: false,
      form: {
        id: null,
        name: '',
        idNo: '',
        examTable: [],
        revPark: [],
        threeInsp: [],
        examTime: '',
      },
      examTable: [],
      revPark: [],
      threeInsp: [],
      show: false,
      minDate: new Date(2024, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
    };
  },
  created() {
    this.getToken();

    this.randomString = this.getRandomString(10);

    const { id, idNo, name } = this.$route.query;
    this.form = {
      ...this.form,
      id,
      idNo,
      name,
    };

    this.getDetail();
  },
  methods: {
    async getDetail() {
      const { data } = await this.$axios.post('/tax/yunDa/queryById', {
        id: this.form.id,
      });
      if (data.success) {
        const { examTable, revPark, threeInsp, examTime } = data.data;
        if (examTable) {
          let arr = examTable.split(','),
            list = [];
          for (const item of arr) {
            let url = await getOssFile(item);
            list.push({
              url,
              isImage: true,
            });
          }
          this.examTable = arr;
          this.form = {
            ...data.data,
            examTable: list,
            examTime,
          };
        }
        if (revPark) {
          const url = await getOssFile(revPark);
          this.form.revPark = [{ url }];
          this.revPark = [revPark];
        }
        if (threeInsp) {
          const url = await getOssFile(threeInsp);
          this.form.threeInsp = [{ url }];
          this.threeInsp = [threeInsp];
        }
      }
    },
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res);
        this.clientOSS = clientOSS;
      });
    },
    onOversize(file) {
      Toast('文件大小不能超过300M');
    },
    async afterReadVideo(file, current) {
      let video = file.file;
      this.uploading = Toast.loading({
        duration: 0,
        message: '视频上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = video.name.substring(video.name.lastIndexOf('.')),
        path = `ling_gong/yunda/${date}/${t}-${this.randomString}-${current}${suffix}`;
      const res = await this.clientOSS.put(path, video, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        this[current].length
          ? this[current].splice(0, 1, path)
          : this[current].push(path);
      }
      this.uploading.clear();
    },
    async afterRead(file, current) {
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `ling_gong/yunda/${date}/${t}-${this.randomString}-${current}${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        this[current].push(path);
      }
      this.uploading.clear();
    },
    handleCompressor(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          // quality: 0.3,
          maxWidth: 1000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    getRandomString(len) {
      len = len || 32;
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        maxPos = chars.length,
        pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    onSubmit(values) {
      console.log('form', this.form);
      this.handleSubmit();
    },
    async handleSubmit() {
      let postData = {
        ...this.form,
        examTable: this.examTable.join(','),
        revPark: this.revPark[0],
        threeInsp: this.threeInsp[0],
      };
      console.log(postData);
      this.isSubmiting = true;
      const { data } = await this.$axios.post(
        'tax/yunDa/leaderCommit',
        postData
      );
      if (data.success) {
        this.$router.back();
      }
      this.isSubmiting = false;
    },
    handleDelete(file, current) {
      this.examTable.splice(current, 1);
    },
    onConfirm(value) {
      this.form.examTime = this.$dayjs(value).format('YYYY-MM-DD');
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/vars';

.Exam {
  min-height: calc(100vh - 0.46rem);
  background: #fff;
  padding: 0.46rem 0.16rem 0.32rem;

  .item-image {
    position: relative;
    z-index: 10;
    width: 0.8rem;
    height: 0.8rem;

    .van-uploader {
      z-index: -10;
      pointer-events: none;
    }

    .van-uploader__preview {
      display: none;
    }

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 0.8rem;
      height: 0.8rem;
      background-color: #f7f8fa;

      img {
        width: 100%;
        height: 100%;
      }

      .van-icon {
        color: #dcdee0;
        font-size: 0.24rem;
      }
    }
  }

  .btn {
    display: block;
    width: 100%;
    height: 0.44rem;
    background-color: $orange;
    margin: 0.32rem auto 0;
    padding: 0;
    border: none;
    border-radius: 0.22rem;
    color: #fff;
    font-size: 0.17rem;
    box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);

    &:disabled {
      background-color: $gray !important;
    }
  }
}
</style>
