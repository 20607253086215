<template>
  <div>
    <van-notice-bar
      v-if="form.status == 4"
      wrapable
      :scrollable="false"
      :text="form.authRemark"
    />
    <van-form @submit="onSubmit">
      <van-divider content-position="left">身份证</van-divider>
      <van-field
        v-model="form.name"
        label="姓名"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.gender"
        label="性别"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.idNo"
        label="身份证号"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.nation"
        label="民族"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.registerAddress"
        label="籍贯"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.birthday"
        label="出生日期"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.liveAddress"
        label="家庭住址"
        type="textarea"
        rows="1"
        autosize
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.issuingAuthority"
        label="发证机关"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.issuingDate"
        label="证件有效期起"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.loseDate"
        label="证件有效期止"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.bankCode"
        label="银行卡号"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.bank"
        label="所属银行"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.mobile"
        label="手机号码"
        input-align="right"
        readonly
      />

      <van-divider content-position="left">驾驶证</van-divider>
      <van-field
        v-model="form.driverLicenseNo"
        label="驾驶证号"
        input-align="right"
        readonly
      />
      <van-field
        label="准驾车型"
        v-model="form.driverLicenseType"
        input-align="right"
        readonly
      />
      <van-field
        label="初次领证日期"
        v-model="form.driverLicenseInit"
        input-align="right"
        readonly
      />
      <van-field
        label="有效期起"
        v-model="form.driverLicenseStart"
        input-align="right"
        readonly
      />
      <van-field
        label="有效期止"
        v-model="form.driverLicenseEnd"
        input-align="right"
        readonly
      />

      <van-divider content-position="left">资格证</van-divider>
      <van-field
        v-model="form.certType"
        label="资格证类别"
        placeholder="请输入"
        input-align="right"
        readonly
      />
      <van-field
        label="初次领证日期"
        v-model="form.certInit"
        input-align="right"
        readonly
      />
      <van-field
        label="有效期起"
        v-model="form.certStart"
        input-align="right"
        readonly
      />
      <van-field
        label="有效期止"
        v-model="form.certEnd"
        input-align="right"
        readonly
      />

      <van-divider content-position="left">体检单</van-divider>
      <van-field
        v-model="form.bodyReportTime"
        label="体检日期"
        input-align="right"
        readonly
      />

      <div class="actions">
        <button class="btn btn-plain" @click="handleBackward">重新上传</button>
        <button :disabled="isSubmiting" class="btn" native-type="submit">
          提交认证
        </button>
      </div>
    </van-form>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  DatetimePicker,
  Dialog,
  Divider,
  Field,
  Form,
  NoticeBar,
  Popup,
  RadioGroup,
  Radio,
  Toast,
  Uploader,
} from 'vant';

Vue.use(DatetimePicker)
  .use(Dialog)
  .use(Divider)
  .use(Field)
  .use(Form)
  .use(NoticeBar)
  .use(Popup)
  .use(RadioGroup)
  .use(Radio)
  .use(Toast)
  .use(Uploader);

export default {
  name: 'Finish',
  props: ['id', 'yunDaUid', 'yunDaComId'],
  data() {
    return {
      isSubmiting: false,
      uploading: false,
      form: {
        name: '',
        idNo: '',
        gender: '',
        nation: '',
        registerAddress: '',
        birthday: '',
        liveAddress: '',
        issuingAuthority: '',
        issuingDate: '',
        loseDate: '',
        bankCode: '',
        bank: '',
        mobile: '',
        driverLicenseNo: '',
        driverLicenseType: '',
        driverLicenseInit: '',
        driverLicenseStart: '',
        driverLicenseEnd: '',
        certType: '货运',
        certInit: '',
        certStart: '',
        certEnd: '',
        bodyReportTime: '',
      },
    };
  },
  watch: {
    id: function (n, o) {
      this.taxId = n;
      this.getDetail();
    },
  },
  created() {},
  mounted() {
    this.taxId = this.id;
    this.taxId && this.getDetail();
  },
  methods: {
    async getDetail() {
      const { data } = await this.$axios.post('/tax/wechat/yunDa/queryDriver', {
        yunDaUid: this.yunDaUid,
        yunDaComId: this.yunDaComId,
        taxId: this.taxId,
      });
      if (data.success) {
        this.form = data.data;
      }
    },
    onSubmit(values) {
      // console.log('form', this.form);
      this.handleSubmit();
    },
    async handleSubmit() {
      let postData = {
        taxId: this.taxId,
        yunDaUid: this.yunDaUid,
        yunDaComId: this.yunDaComId,
      };
      this.isSubmiting = true;
      const res = await this.$axios.post(
        '/tax/wechat/yunDa/userCommit',
        postData
      );
      if (res.status != 200) {
        this.showError(`系统繁忙-${res.status}`);
        this.isSubmiting = false;
        return;
      } else {
        const { data } = res;
        if (data.rtnCode == 202) {
          this.showError(data.rtnMessage);
          this.isSubmiting = false;
          return;
        }
        if (data.success) {
          const { accountId, taskId, contract } = data.data;
          if (contract) {
            this.getUrl(taskId, accountId);
          } else {
            Toast({
              type: 'success',
              message: '已提交完成',
              forbidClick: true,
            });
          }
          this.getDetail();
        }
        this.isSubmiting = false;
      }
    },
    showError(msg) {
      Dialog.confirm({
        confirmButtonText: '重新提交',
        confirmButtonColor: '#ff6d41',
        cancelButtonText: '切换其他认证渠道',
        cancelButtonColor: '#969799',
        title: '提示',
        message: `提交失败: ${msg}，请稍后重试(若多次尝试仍失败，您可切换至其他认证渠道)`,
      })
        .then(() => {
          this.handleSubmit();
        })
        .catch(() => {
          window.location.href = 'thirdh5jump.driverapp.com/ylm/auth';
        });
    },
    async getUrl(taskId, accountId) {
      this.uploading = Toast.loading({
        message: '请稍候',
        forbidClick: true,
      });
      const { data } = await this.$axios.post(
        '/tax/wechatController/doEleSign',
        {
          taskId,
          accountId,
        }
      );
      if (data.success) {
        if (data.data.shortUrl) {
          window.location.href = data.data.shortUrl;
        }
      }
      this.uploading.clear();
    },
    handleBackward() {
      this.$emit('changeCurrent', 0);
    },
  },
};
</script>

<style lang="scss">
// @import '@/styles/vars';
</style>
