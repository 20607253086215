<template>
  <div class="DriverDetail">
    <van-nav-bar
      fixed
      left-arrow
      title="司机信息"
      @click-left="$router.back()"
    />

    <van-form>
      <van-divider content-position="left">身份证</van-divider>
      <van-field
        v-model="form.name"
        label="姓名"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.gender"
        label="性别"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.idNo"
        label="身份证号"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.nation"
        label="民族"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.registerAddress"
        label="籍贯"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.birthday"
        label="出生日期"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.liveAddress"
        label="家庭住址"
        type="textarea"
        rows="1"
        autosize
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.issuingAuthority"
        label="发证机关"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.issuingDate"
        label="证件有效期起"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.loseDate"
        label="证件有效期止"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.bankCode"
        label="银行卡号"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.bank"
        label="所属银行"
        input-align="right"
        readonly
      />
      <van-field
        v-model="form.mobile"
        label="手机号码"
        input-align="right"
        readonly
      />

      <van-divider content-position="left">驾驶证</van-divider>
      <van-field
        v-model="form.driverLicenseNo"
        label="驾驶证号"
        input-align="right"
        readonly
      />
      <van-field
        label="准驾车型"
        v-model="form.driverLicenseType"
        input-align="right"
        readonly
      />
      <van-field
        label="初次领证日期"
        v-model="form.driverLicenseInit"
        input-align="right"
        readonly
      />
      <van-field
        label="有效期起"
        v-model="form.driverLicenseStart"
        input-align="right"
        readonly
      />
      <van-field
        label="有效期止"
        v-model="form.driverLicenseEnd"
        input-align="right"
        readonly
      />

      <van-divider content-position="left">资格证</van-divider>
      <van-field
        v-model="form.certType"
        label="资格证类别"
        placeholder="请输入"
        input-align="right"
        readonly
      />
      <van-field
        label="初次领证日期"
        v-model="form.certInit"
        input-align="right"
        readonly
      />
      <van-field
        label="有效期起"
        v-model="form.certStart"
        input-align="right"
        readonly
      />
      <van-field
        label="有效期止"
        v-model="form.certEnd"
        input-align="right"
        readonly
      />

      <van-divider content-position="left">体检单</van-divider>
      <van-field
        v-model="form.bodyReportTime"
        label="体检日期"
        input-align="right"
        readonly
      />
    </van-form>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  DatetimePicker,
  Divider,
  Field,
  Form,
  Popup,
  RadioGroup,
  Radio,
  Toast,
  Uploader,
} from 'vant';

Vue.use(DatetimePicker)
  .use(Divider)
  .use(Field)
  .use(Form)
  .use(Popup)
  .use(RadioGroup)
  .use(Radio)
  .use(Toast)
  .use(Uploader);

export default {
  name: 'Identity',
  data() {
    return {
      isSubmiting: false,
      uploading: false,
      form: {},
    };
  },
  created() {
    const { id } = this.$route.query;
    this.id = id;
    this.getDetail();
  },
  methods: {
    async getDetail() {
      const { data } = await this.$axios.post('/tax/yunDa/queryById', {
        id: this.id,
      });
      if (data.success) {
        this.form = data.data;
      }
    },
  },
};
</script>

<style lang="scss">
// @import '@/styles/vars';

.DriverDetail {
  background: #fff;
  padding: 0.46rem 0.16rem 0.32rem;
}
</style>
