<template>
  <div>
    <van-form @submit="onSubmit">
      <van-field
        label="驾驶证主页"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <template #input>
          <van-uploader
            ref="driverLicense"
            :after-read="(file) => afterRead(file, 'driverLicense')"
            :max-count="1"
            @delete="
              (file, current) => handleDelete(file, current, 'driverLicense')
            "
            v-model="form.driverLicense"
            accept="image/*"
          >
          </van-uploader>
        </template>
      </van-field>

      <van-field
        label="驾驶证副页"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <template #input>
          <van-uploader
            ref="driverLicenseRec"
            :after-read="(file) => afterRead(file, 'driverLicenseRec')"
            :max-count="1"
            @delete="
              (file, current) => handleDelete(file, current, 'driverLicenseRec')
            "
            v-model="form.driverLicenseRec"
            accept="image/*"
          >
          </van-uploader>
        </template>
      </van-field>

      <van-field
        v-model="form.driverLicenseNo"
        label="驾驶证号"
        placeholder="请输入"
        :rules="[{ required: true, message: '' }]"
      />

      <van-field
        name="radio"
        label="准驾车型"
        :rules="[{ required: true, message: '' }]"
      >
        <template #input>
          <van-radio-group
            v-model="form.driverLicenseType"
            direction="horizontal"
          >
            <van-radio name="A1" checked-color="#ff6d41">A1</van-radio>
            <van-radio name="A2" checked-color="#ff6d41">A2</van-radio>
            <van-radio name="B1" checked-color="#ff6d41">B1</van-radio>
            <van-radio name="B2" checked-color="#ff6d41">B2</van-radio>
            <van-radio name="C1" checked-color="#ff6d41">C1</van-radio>
            <van-radio name="C2" checked-color="#ff6d41">C2</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
        v-model="form.driverLicenseInit"
        label="初次领证日期"
        placeholder="请选择"
        readonly
        @click="
          show = true;
          currentDate = new Date(form.driverLicenseInit);
          current = 'driverLicenseInit';
        "
        right-icon="arrow"
        :rules="[{ required: true, message: '' }]"
      />

      <van-field
        v-model="form.driverLicenseStart"
        label="有效期起"
        placeholder="请选择"
        readonly
        @click="
          show = true;
          currentDate = new Date(form.driverLicenseStart);
          current = 'driverLicenseStart';
        "
        right-icon="arrow"
        :rules="[{ required: true, message: '' }]"
      />

      <van-field
        v-model="form.driverLicenseEnd"
        label="有效期止"
        placeholder="请选择"
        readonly
        @click="
          show = true;
          currentDate = new Date(form.driverLicenseEnd);
          current = 'driverLicenseEnd';
        "
        right-icon="arrow"
        :rules="[{ required: true, message: '' }]"
      />

      <div class="actions">
        <button class="btn btn-plain" @click="handleBackward">上一步</button>
        <button :disabled="isSubmiting" class="btn" native-type="submit">
          下一步
        </button>
      </div>
    </van-form>

    <van-popup v-model="show" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  DatetimePicker,
  Dialog,
  Divider,
  Field,
  Form,
  Popup,
  RadioGroup,
  Radio,
  Toast,
  Uploader,
} from 'vant';
import OSS from 'ali-oss';
import Compressor from 'compressorjs';
import { getOssToken, getOssFile } from '@/utils/getOss';

Vue.use(DatetimePicker)
  .use(Dialog)
  .use(Divider)
  .use(Field)
  .use(Form)
  .use(Popup)
  .use(RadioGroup)
  .use(Radio)
  .use(Toast)
  .use(Uploader);

export default {
  name: 'Driver',
  props: ['id', 'yunDaUid', 'yunDaComId'],
  data() {
    return {
      clientOSS: {},
      isSubmiting: false,
      uploading: false,
      form: {
        driverLicense: [],
        driverLicenseRec: [],
        driverLicenseNo: '',
        driverLicenseType: '',
        driverLicenseInit: '',
        driverLicenseStart: '',
        driverLicenseEnd: '',
        idNo: '',
      },
      driverLicense: [],
      driverLicenseRec: [],
      show: false,
      minDate: new Date(1960, 0, 1),
      maxDate: new Date(2060, 11, 31),
      currentDate: new Date(),
      current: '',
      taxId: null,
    };
  },
  watch: {
    id: function (n, o) {
      this.taxId = n;
      this.getDetail();
    },
  },
  created() {
    this.getToken();

    this.randomString = this.getRandomString(10);
  },
  mounted() {
    this.taxId = this.id;
    this.taxId && this.getDetail();
  },
  methods: {
    async getDetail() {
      const { data } = await this.$axios.post('/tax/wechat/yunDa/queryDriver', {
        yunDaUid: this.yunDaUid,
        yunDaComId: this.yunDaComId,
        taxId: this.taxId,
      });
      if (data.success) {
        const { driverLicense, driverLicenseRec, idNo } = data.data;
        this.form.idNo = idNo;
        if (driverLicense && driverLicenseRec) {
          const front = await getOssFile(driverLicense),
            rec = await getOssFile(driverLicenseRec);
          this.form = {
            ...data.data,
            driverLicense: [
              {
                url: front,
                isImage: true,
              },
            ],
            driverLicenseRec: [
              {
                url: rec,
                isImage: true,
              },
            ],
          };
          this.driverLicense = [driverLicense];
          this.driverLicenseRec = [driverLicenseRec];
        }
      }
    },
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res);
        this.clientOSS = clientOSS;
      });
    },
    chooseFile(type) {
      this.$refs[type].chooseFile();
    },
    async afterRead(file, current) {
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `ling_gong/yunda/${date}/${t}-${this.randomString}-${current}${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        this[current].push(path);

        const url = await getOssFile(path);
        console.log(res);
        if (current == 'driverLicense') {
          this.handleOcr(url);
        }
      }
      this.uploading.clear();
    },
    handleCompressor(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          // quality: 0.3,
          maxWidth: 1000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    getRandomString(len) {
      len = len || 32;
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        maxPos = chars.length,
        pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    handleDelete(file, current, type) {
      this[type].splice(current, 1);
    },
    onConfirm(value) {
      this.form[this.current] = this.$dayjs(value).format('YYYY-MM-DD');
      this.current = '';
      this.show = false;
    },
    onSubmit(values) {
      console.log('form', this.form);
      this.handleSubmit();
    },
    async handleSubmit() {
      let postData = {
        ...this.form,
        driverLicense: this.driverLicense[0],
        driverLicenseRec: this.driverLicenseRec[0],
        taxId: this.taxId,
        yunDaUid: this.yunDaUid,
        yunDaComId: this.yunDaComId,
      };
      this.isSubmiting = true;
      const res = await this.$axios.post(
        '/tax/wechat/yunDa/saveDriverColl',
        postData
      );
      if (res.status != 200) {
        this.showError(`系统繁忙-${res.status}`);
        return;
      } else {
        const { data } = res;
        if (data.rtnCode == 202) {
          this.showError(data.rtnMessage);
          this.isSubmiting = false;
          return;
        }
        if (data.success) {
          this.$emit('changeCurrent', 2);
        }
        this.isSubmiting = false;
      }
    },
    showError(msg) {
      Dialog.confirm({
        confirmButtonText: '重新提交',
        confirmButtonColor: '#ff6d41',
        cancelButtonText: '切换其他认证渠道',
        cancelButtonColor: '#969799',
        title: '提示',
        message: `提交失败: ${msg}，请稍后重试(若多次尝试仍失败，您可切换至其他认证渠道)`,
      })
        .then(() => {
          this.handleSubmit();
        })
        .catch(() => {
          window.location.href = 'thirdh5jump.driverapp.com/ylm/auth';
        });
    },
    async handleOcr(url) {
      const { data } = await this.$axios.post('/tool/baidu/urlDriverOcr', {
        url,
        side: 'front',
      });
      if (data.success) {
        let {
          证号: driverLicenseNo,
          准驾车型: driverLicenseType,
          初次领证日期: driverLicenseInit,
          有效期限: driverLicenseStart,
          至: driverLicenseEnd,
        } = data.data.words_result;
        this.form.driverLicenseNo = driverLicenseNo.words || '';
        this.form.driverLicenseType = driverLicenseType.words || '';
        this.form.driverLicenseInit =
          this.$dayjs(driverLicenseInit.words).format('YYYY-MM-DD') || '';
        this.form.driverLicenseStart =
          this.$dayjs(driverLicenseStart.words).format('YYYY-MM-DD') || '';
        this.form.driverLicenseEnd =
          this.$dayjs(driverLicenseEnd.words).format('YYYY-MM-DD') || '';
      }
      this.uploading.clear();
    },
    handleBackward() {
      this.$emit('changeCurrent', 0);
    },
  },
};
</script>

<style lang="scss">
// @import '@/styles/vars';
</style>
